@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@700&display=swap');

.App {
    display: block;
}

.page-content {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.notes-list {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}

.notes-list li {
    list-style-type: none;
    padding: 2pt;
}

.notes-list li::before {
    content: "Ϩ";
    padding-right: 10px;
    padding-left: 2px;
    font-size: 0.9rem;
    color: hsl(162, 73%, 44%);;
    animation: color-change 3s ease-in-out infinite;
    font-weight: bold;
}

.notes-list li b {
    font-family: monospace;
    /* font-size: 1.2rem; */
}

.notes-list li a {
    color: #c6c6c6;
    animation: color-change 3s ease-in-out infinite;
    /* padding-left: 7px; */
    /* font-family: cursive; */
    /* font-size: 1.4rem; */
    /* text-decoration: none; */
}

@keyframes color-change {
/* hue rotate */
0% {
    filter: hue-rotate(0deg);
    }
    
    50% {
    filter: hue-rotate(100deg);
    }
    
    100% {
    filter: hue-rotate(0deg);
    }
}

@media screen and (max-width: 600px) {
    .page-content {
        font-size: 16px;
    }

    .img-container {
        position: relative;
        bottom: 10px;
        right: 10px;
    }
    
}

@media screen and (min-width: 600px) {
    .page-content {
        font-size: 20px;
    }

    .img-container {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
    
}

.img-container div {
    color: #c6c6c6;
    text-decoration: underline;
}

.img-container img {
    width: 150px;
}