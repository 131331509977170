@import url('https://fonts.googleapis.com/css2?family=EB+Garamond');


.App {
	display: block;
	font-family: 'Garamond', 'EB Garamond', serif;
	/* font-weight: 700; */
}


/* cover() */
@media (min-width: 400px) {
	.tbt-1 {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	.ti-1 {
		margin-top: 6px;
		font-size: 1.6em;
		padding: 10px;
	}
	.tt-1 {
		font-size: 3.6em;
		font-weight: 900;
		padding: 20px;
		text-decoration: underline solid #2e1d9c 10%;
		margin-left: 10px;
	}
}

@media (max-width: 399px) {
	.tbt-1 {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.ti-1 {
		margin-top: 6px;
		font-size: 1.6em;
	}
	.tt-1 {
		font-size: 3.6em;
		font-weight: 900;
		padding: 5px;
		text-decoration: underline;
		text-decoration-color: #2e1d9c;
	}
}

.ti-1 a {
	color: #c6c6c6;
	text-decoration: none;
	font-size: larger;
	margin: 0px 10px;
}

/* project_list() */
.cb-1 {
	display: flex;
	flex-direction: column;
	margin-bottom: 50px;

	max-width: 1100px;
	margin-left: auto;
	margin-right: auto;
}

.subsection {
	margin-top: 40px;
	font-size: 40px;
	font-weight: 700;
	padding-left: 20px;
	margin-bottom: 10px;
}

.pb-1 { /* project box */
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-left: 20px;
}

.project {
	color: black;
	text-decoration: none;
	margin: 2px;
}
	
.project {
	background-color: transparent;
	perspective: 1000px;
	width: 250px;
	height: 250px;
	text-decoration: none;
	border: 2px black solid;
	border-radius: 5%;
}

.project .image {
	max-width: 250px;
	opacity: 0.2;
	background-color: black;
	border-radius: 5%;
}

.project:hover .image {
	transition: .5s ease;
	opacity: 1;
	color: black;
	
}

.project .title {
	font-weight: 700;
	z-index: 200;
	font-size: x-large;
	margin: 4px;
}

.project-front {
	z-index: 200;
	position: absolute;
	top: 0;
	font-size: large;
	margin-left: 6px;
	margin-right: 6px;
	color: white;
	text-shadow: -1px 1px 2px #000,
				  1px 1px 2px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000;
}

.ab-1 {
	max-width: 1100px;
	margin-left: auto;
	margin-right: auto;
}

.abt-1 {
	font-size: 1.2em;
}

.abt-2 {
	margin: 10px;
	text-decoration: underline solid #2e1d9c 20%;
	-webkit-text-decoration: underline solid #2e1d9c 20%;
}

.abt-2 li {
	font-size: larger;
}

.abt-3 li {
	margin: 10px;
}

.ab-1 a {
	color: #3925be;
	text-decoration: none;
}

.ab-1 a:hover {
	color: #2e1d9c;
	text-decoration: underline;
}

.ab-1 a:visited {
	color: #9989ff;
	text-decoration: none;
}