.information-prompt {
    font-family: Arial, sans-serif;
    font-size: 18px;
    line-height: 1.5;
    text-align: center;
    padding: 20px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;

    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    max-height: 300px;
    overflow: scroll;
}

.App {
  height: 100%;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

label {
    font-family: Arial, sans-serif;
    font-size: 16px;
    margin-bottom: 10px;
}

input {
    width: 200px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
}

button {
    padding: 10px 20px;
    font-family: Arial, sans-serif;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.coordinates-table {
    display: none;
}

  @media print {
    .information-prompt {
        display: none;
    }
    button {
        display: none;
    }
    .coordinates-table {
        display: inline;
        width: 100%;
        border-collapse: collapse;
        font-family: Arial, sans-serif;
        color: #333;
      }
      
      .coordinates-table th {
        background-color: #f5f5f5;
        padding: 10px;
        text-align: left;
        font-weight: bold;
        border-bottom: 1px solid #ddd;
      }
      
      .coordinates-table td {
        padding: 10px;
        border-bottom: 1px solid #ddd;
      }
      
      .coordinates-table tbody tr:nth-child(even) {
        background-color: #f9f9f9;
      }
      
      .coordinates-table tbody tr:hover {
        background-color: #f2f2f2;
      }

  }
